<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >

    <div v-if="comments !== null">

      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>نمایش</label>
              <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>تعداد</label>
            </b-col>
          </b-row>

        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="comments"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <template #cell(userId)="data">
            <span class="d-block">{{ data.item.name }}</span>
            <small class="text-muted">@{{ data.item.userId }}</small>
          </template>

          <!--          &lt;!&ndash; Column: createDate &ndash;&gt;-->
          <!--          <template #cell(createDate)="data">-->
          <!--             <span>-->
          <!--              {{data.item.createDate.slice(11,16)}}-->
          <!--              - -->
          <!--              {{createJalaliDate(data.item.createDate.slice(0,10))}}-->
          <!--            </span>-->
          <!--          </template>-->

          <!-- Column: reply -->
          <template #cell(reply)="data">
            <div v-b-modal.modal-reply class="text-primary" @click="selectedCommentId = data.item.blogCommentId ; selectedCommentBlogId = data.item.blogId">
              <feather-icon size="20" icon="Edit2Icon"/>
            </div>
          </template>
          <!-- Column: edit -->
          <template #cell(edit)="data">
            <b-link class="text-warning" :to="{ name: 'pages-blog-comments-edit', params: { id: data.item.blogCommentId } }">
              <feather-icon size="20" icon="EditIcon"/>
            </b-link>
          </template>

          <!-- Column: delete -->
          <template #cell(delete)="data">
            <b-link class="text-danger" v-b-modal.modal-delete @click="showModal(data.item.blogCommentId)">
              <feather-icon size="20" icon="TrashIcon"/>
            </b-link>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteComment(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>

    <b-modal
        id="modal-reply"
        centered
        title="ثبت پاسخ برای دیدگاه کاربر"
        ok-only
        ok-title="ثبت"
        @ok="createNewComment"
    >
      <b-form-group
          label="پاسخ شما :"
          label-for="name-input"
      >
        <b-form-input
            id="name-input"
            v-model="commentMessage"
            required
        />
      </b-form-group>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal,BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Helper from "@/libs/Helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BlogCommentGetAllRequest, CreateBlogCommentRequest} from "@/libs/Api/Blog";
import {BlogCommentDeleteRequest} from "@/libs/Api/Blog";

export default {
  name: "BlogComments",
  title: "لیست نظرات بلاگ - پنل ادمین مکس ",
  data() {
    return {
      comments: [],
      currentPage: 1,
      totalCounts: null,
      deleteItem: null,
      overlay: false,
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {key: 'userId', label: 'شناسه و نام کاربر'},
        {key: 'message', label: 'متن'},
        // { key: 'createDate' ,label :'تاریخ ثبت'},
        {key: 'blogId', label: 'شناسه بلاگ'},
        {key: 'reply', label: 'پاسخ به دیدگاه'},
        {key: 'edit', label: 'ویرایش'},
        {key: 'delete', label: 'حذف'},
      ],
      commentMessage: '',
      selectedCommentId: null,
      selectedCommentBlogId: null
    }
  },
  async created() {
    await this.getComments(this.perPage, this.currentPage);
  },
  methods: {
    refreshData() {
      this.getPayments(this.perPage, this.currentPage, this.searchQuery)
    },
    async getComments(count, pageNumber) {
      let _this = this;
      _this.overlay = true;
      let data = {
        pageNumber: pageNumber,
        count: count,
      }

      let blogCommentGetAllRequest = new BlogCommentGetAllRequest(_this);
      blogCommentGetAllRequest.setParams(data);
      await blogCommentGetAllRequest.fetch(function (content) {
        _this.comments = [];
        content.blogComments.forEach(el => {
          if (el.parentId === 0) {
            _this.comments.push(el);
          }
        })
        // _this.comments = content.blogComments
        _this.totalCounts = content.counts;
        _this.overlay = false;
      }, function (error) {
        _this.overlay = false;
        console.log(error);
      })
    },
    async deleteComment(param) {
      let _this = this;

      let blogCommentDeleteRequest = new BlogCommentDeleteRequest(_this);
      blogCommentDeleteRequest.setId(param);
      await blogCommentDeleteRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        _this.getComments(_this.perPage, _this.currentPage)
      }, function (error) {
        console.log(error)
      })
    },
    async createNewComment() {
      let _this = this;
      _this.overlay = true;

      let data = {
        blogId: _this.selectedCommentBlogId,
        message: _this.commentMessage,
        parentId: _this.selectedCommentId
      }

      let createBlogCommentRequest = new CreateBlogCommentRequest(_this);
      createBlogCommentRequest.setParams(data);
      await createBlogCommentRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `پاسخ شما ثبت شد.`,
          },
        })
        _this.commentMessage = '';
        _this.selectedCommentId = null;
        _this.selectedCommentBlogId = null
        _this.getComments(_this.perPage, _this.currentPage);
      }, function () {
        _this.overlay = false;
        console.log('Error')
      });
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    makePrettyPrice(param) {
      return Helper.prettyPrice(param, 0)
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param
    },
  },
  watch: {
    perPage: function (nv, ov) {
      this.getComments(nv, this.currentPage);
    },
    currentPage: function (nv, ov) {
      this.getComments(this.perPage, nv);
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormGroup,
    vSelect,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
